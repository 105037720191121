  <!-- TODO: {"status":"error","message":"no implicit conversion of Float into String"} -->
<template>
  <div>
    <v-row v-if="showSkeletonLoader">
      <v-col cols="12">
        <v-skeleton-loader type="table" />
      </v-col>
    </v-row>

    <template v-else>
      <v-row v-if="eodClosingUserAggregationsData && eodClosingUserAggregationsData.length">
        <v-col cols="6">
          <h2>ユーザー集計</h2>
        </v-col>
        <v-col
          cols="6"
          class="d-flex"
        >
          <v-spacer />
          <v-btn
            color="primary"
            :ripple="false"
            :disabled="!canEnqueueJobs.userAggregations"
            :loading="isAnyJobInProgress.userAggregations"
            @click="enqueueUserAggregations"
          >
            <v-icon left>
              {{ icons.mdiSync }}
            </v-icon>
            日計ジョブ一括実行
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-for="agg in eodClosingUserAggregationsData"
        :key="`aggregation-${agg.id}`"
      >
        <v-col cols="12">
          <v-skeleton-loader
            v-if="jobStatuses[agg.id] === 'in_progress'"
            type="table"
          />
          <template v-else-if="eodClosingUserAggregationsMap[agg.id]">
            <EodClosingAggregationTable
              :aggregation="eodClosingUserAggregationsMap[agg.id]"
              :loading="loadingStatuses[agg.id]"
              :aggregation-users="aggregationUsers(agg.id)"
              :available-users="availableUsers[agg.id]"
              @save-all="enqueueGenerateBusinessRuleResults"
              @save="generateBusinessRuleResult"
              @reload="reloadAggregation"
              @refresh="refreshAggregation"
              @create-user-aggregation="createEodClosingUserAggregation(agg.id, $event)"
              @destroy-user-aggregation="destroyEodClosingUserAggregation(agg.id, $event)"
              @fetch-available-users="handleFetchAvailableUsers(agg.id, $event)"
            />
            <p
              v-if="jobTimestamps[agg.id].createdAt && jobTimestamps[agg.id].updatedAt"
              class="text-xs secondary--text text-right"
            >
              <span class="mr-2">最終集計日時:</span>
              {{ showFormattedTimestamp(jobTimestamps[agg.id].updatedAt) }}
            </p>
          </template>
          <AggregationEmptyTable
            v-else
            :id="agg.id"
            :name="agg.attributes.name"
          >
            <template #toolbar-addons>
              <v-btn
                :ripple="false"
                :loading="loadingStatuses[agg.id]"
                :disabled="loadingStatuses[agg.id]"
                color="secondary"
                small
                @click="reloadAggregation(agg.id)"
              >
                <v-icon left>
                  {{ icons.mdiReload }}
                </v-icon>
                個別実行
              </v-btn>
            </template>
          </AggregationEmptyTable>
        </v-col>
      </v-row>

      <v-row v-if="eodClosingClubAggregationsData && eodClosingClubAggregationsData.length">
        <v-col cols="6">
          <h2>店舗集計</h2>
        </v-col>
        <v-col
          cols="6"
          class="d-flex"
        >
          <v-spacer />
          <v-btn
            color="primary"
            :ripple="false"
            :disabled="!canEnqueueJobs.clubAggregations"
            :loading="isAnyJobInProgress.clubAggregations"
            @click="enqueueClubAggregations"
          >
            <v-icon left>
              {{ icons.mdiSync }}
            </v-icon>
            日計ジョブ一括実行
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-for="agg in eodClosingClubAggregationsData"
        :key="`aggregation-${agg.id}`"
      >
        <v-col cols="12">
          <v-skeleton-loader
            v-if="jobStatuses[agg.id] === 'in_progress'"
            type="table-heading, list-item-two-line"
          />
          <template v-else-if="eodClosingClubAggregationsMap[agg.id]">
            <EodClosingAggregationTable
              :aggregation="eodClosingClubAggregationsMap[agg.id]"
              :loading="loadingStatuses[agg.id]"
              @save-all="enqueueGenerateBusinessRuleResults"
              @save="generateBusinessRuleResult"
              @reload="reloadAggregation"
              @refresh="refreshAggregation"
            />
            <p
              v-if="jobTimestamps[agg.id].createdAt && jobTimestamps[agg.id].updatedAt"
              class="text-xs secondary--text text-right"
            >
              <span class="mr-2">最終集計日時:</span>
              {{ showFormattedTimestamp(jobTimestamps[agg.id].updatedAt) }}
            </p>
          </template>
          <AggregationEmptyTable
            v-else
            :id="agg.id"
            :name="agg.attributes.name"
          >
            <template #toolbar-addons>
              <v-btn
                :ripple="false"
                :loading="loadingStatuses[agg.id]"
                :disabled="loadingStatuses[agg.id]"
                color="secondary"
                small
                @click="reloadAggregation(agg.id)"
              >
                <v-icon left>
                  {{ icons.mdiReload }}
                </v-icon>
                個別実行
              </v-btn>
            </template>
          </AggregationEmptyTable>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ref,
  reactive,
  computed,
  toRefs,
  onMounted,
  getCurrentInstance,
} from '@vue/composition-api'
import { mdiSync, mdiReload } from '@mdi/js'
import { parseISO, format } from 'date-fns'
import AggregationApi from '@/api/v2/eod-closing/Aggregation'
import AggregationUserApi from '@/api/v2/eod-closing/AggregationUser'
import BusinessRuleApi from '@/api/v2/eod-closing/BusinessRule'
import useAggregation from '../composables/useAggregation'
import useCurrentData from '@/views/composable/useCurrentData'
import EodClosingAggregationTable from './AggregationTable.vue'
import AggregationEmptyTable from './AggregationEmptyTable.vue'
import { handleApiError } from '@/utils/errorHandler'

export default {
  components: {
    EodClosingAggregationTable,
    AggregationEmptyTable,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const { date } = toRefs(props)

    const isLoading = ref(true)
    const aggregations = ref([])
    const aggregationsData = ref([])
    const loadingStatuses = reactive({})
    const jobTimestamps = reactive({})
    const jobStatuses = ref({})
    const availableUsers = ref({})

    const {
      aggregationResultsMap,
      findDependents,
      updateAggregationResult,
      sortAndFilterAggregationType,
      buildAggregationMap,
    } = useAggregation(aggregations)

    const { currentClub } = useCurrentData()

    const showSkeletonLoader = computed(() => isLoading.value || !date.value)

    const eodClosingUserAggregationsData = computed(() => sortAndFilterAggregationType(aggregationsData.value, 'EodClosingUserAggregation'))
    const eodClosingClubAggregationsData = computed(() => sortAndFilterAggregationType(aggregationsData.value, 'EodClosingClubAggregation'))
    const eodClosingUserAggregationsMap = computed(() => buildAggregationMap(sortAndFilterAggregationType(aggregations.value, 'EodClosingUserAggregation')))
    const eodClosingClubAggregationsMap = computed(() => buildAggregationMap(sortAndFilterAggregationType(aggregations.value, 'EodClosingClubAggregation')))
    const isAnyJobInProgress = computed(() => {
      return {
        userAggregations: eodClosingUserAggregationsData.value.some(agg => jobStatuses.value[agg.id] === 'in_progress'),
        clubAggregations: eodClosingClubAggregationsData.value.some(agg => jobStatuses.value[agg.id] === 'in_progress'),
      }
    })
    const isAllJobCompleted = computed(() => {
      return {
        userAggregations: eodClosingUserAggregationsData.value.every(agg => jobStatuses.value[agg.id] === 'completed'),
        clubAggregations: eodClosingClubAggregationsData.value.every(agg => jobStatuses.value[agg.id] === 'completed'),
      }
    })
    const isAnyJobMissing = computed(() => {
      return {
        userAggregations: eodClosingUserAggregationsData.value.some(agg => jobStatuses.value[agg.id] === 'failed' || jobStatuses.value[agg.id] === null),
        clubAggregations: eodClosingClubAggregationsData.value.some(agg => jobStatuses.value[agg.id] === 'failed' || jobStatuses.value[agg.id] === null),
      }
    })
    const isAllJobMissing = computed(() => {
      return {
        userAggregations: eodClosingUserAggregationsData.value.every(agg => jobStatuses.value[agg.id] === 'failed' || jobStatuses.value[agg.id] === null),
        clubAggregations: eodClosingClubAggregationsData.value.every(agg => jobStatuses.value[agg.id] === 'failed' || jobStatuses.value[agg.id] === null),
      }
    })
    const canEnqueueJobs = computed(() => {
      return {
        userAggregations: isAllJobMissing.value.userAggregations && !isAllJobCompleted.value.userAggregations && !isAnyJobInProgress.value.userAggregations,
        clubAggregations: isAllJobMissing.value.clubAggregations && !isAllJobCompleted.value.clubAggregations && !isAnyJobInProgress.value.clubAggregations,
      }
    })

    const aggregationUsers = computed(() => id => {
      const agg = aggregationsData.value.find(ag => ag.attributes.id === Number(id))
      if (agg?.attributes?.type === 'EodClosingUserAggregation') {
        return agg.attributes.aggregationUsers.data
      }

      return []
    })

    const showFormattedTimestamp = timestampStr => {
      if (!timestampStr) return ''

      return format(parseISO(timestampStr), 'yyyy/MM/dd HH:mm')
    }

    const upsertAggregation = data => {
      const idx = aggregations.value.findIndex(ag => ag.attributes.id === data.attributes.id)

      if (idx >= 0) {
        aggregations.value.splice(idx, 1, data)
      } else {
        aggregations.value.push(data)
      }
    }

    const getAggregationJobStatus = async id => {
      try {
        const res = await AggregationApi.getAggregationJobStatus(id, date.value)
        if (res.data.status === 'success') {
          const { status, result, meta } = res.data.data
          jobStatuses.value = { ...jobStatuses.value, [id]: status }
          jobTimestamps[id] = { createdAt: meta.createdAt, updatedAt: meta.updatedAt }
          if (result) upsertAggregation(result.data)
        }
      } catch (error) {
        if (error.response.status === 404) {
          jobStatuses.value = { ...jobStatuses.value, [id]: null }
        } else {
          handleApiError(error, { $toast: vm.$toast })
        }
      }
    }

    const pollAggregationJobStatus = async id => {
      let intervalTime = 5000 // 初期間隔 5 秒
      const interval = setInterval(async () => {
        try {
          await getAggregationJobStatus(id)
          if (['completed', 'failed', null].includes(jobStatuses.value[id])) {
            clearInterval(interval)
          }
        } catch (error) {
          clearInterval(interval)
          handleApiError(error, { $toast: vm.$toast })
        }
        intervalTime = Math.min(intervalTime * 2, 60000) // 最大1分まで増加
      }, intervalTime)
    }

    const enqueueAggregation = async (id, options = {}) => {
      const { forceReload = false, forceRefresh = false } = options
      try {
        const res = await AggregationApi.enqueueAggregation(id, date.value, { forceReload, forceRefresh })
        if (res.status === 202) {
          jobStatuses.value = { ...jobStatuses.value, [id]: 'in_progress' }
          pollAggregationJobStatus(id)
        }
      } catch (error) {
        if (error.response.status === 404) {
          jobStatuses.value = { ...jobStatuses.value, [id]: null }
        } else {
          handleApiError(error, { $toast: vm.$toast })
        }
      }
    }

    // 初期化時
    const getAggregations = async () => {
      isLoading.value = true
      try {
        const res = await AggregationApi.getAggregations(date.value)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          aggregationsData.value = data
          const aggregationIds = []
          data.forEach(async ag => {
            Vue.set(loadingStatuses, ag.attributes.id, false)
            Vue.set(jobTimestamps, ag.attributes.id, { createdAt: null, updatedAt: null })
            jobStatuses.value = { ...jobStatuses.value, [ag.attributes.id]: 'in_progress' }
            availableUsers.value = { ...availableUsers.value, [ag.attributes.id]: [] }
            aggregationIds.push(ag.attributes.id)
          })
          // jobステータス確認
          await Promise.all(aggregationIds.map(id => getAggregationJobStatus(id)))
        }
      } catch (error) {
        handleApiError(error, { $toast: vm.$toast })
      } finally {
        isLoading.value = false
      }
    }

    // 保存結果以外を再計算
    const reloadAggregation = id => {
      enqueueAggregation(id, { forceReload: true })
    }

    // 保存結果を削除してすべてを再計算
    const refreshAggregation = id => {
      enqueueAggregation(id, { forceReload: true, forceRefresh: true })
    }

    // 一括保存（手動で値を変更しての保存はないので依存関係は気にしなくていい）
    const enqueueGenerateBusinessRuleResults = async (id, options = {}) => {
      const { forceReload = false, forceRefresh = false } = options
      try {
        const res = await BusinessRuleApi.enqueueGenerateBusinessRuleResults(id, date.value, { forceReload, forceRefresh })
        if (res.status === 202) {
          jobStatuses.value = { ...jobStatuses.value, [id]: 'in_progress' }
          pollAggregationJobStatus(id)
        }
      } catch (error) {
        if (error.response.status === 404) {
          jobStatuses.value = { ...jobStatuses.value, [id]: null }
        } else {
          handleApiError(error, { $toast: vm.$toast })
        }
      }
    }

    const getUserDependents = (businessRuleId, contextId) => {
      return [
        ...findDependents({
          aggregations: aggregations.value,
          businessRuleId,
          context: { contextId, contextType: 'User' },
          excludeDependent: { contextId, contextType: 'User', businessRuleId },
        }),
        ...findDependents({
          aggregations: aggregations.value,
          businessRuleId,
          context: { contextId: currentClub.value.id, contextType: 'Club' },
        }),
      ]
    }
    const getClubDependents = businessRuleId => {
      return [
        ...findDependents({
          aggregations: aggregations.value,
          businessRuleId,
          context: { contextId: null, contextType: 'User' },
        }),
        ...findDependents({
          aggregations: aggregations.value,
          businessRuleId,
          context: { contextId: currentClub.value.id, contextType: 'Club' },
          excludeDependent: { contextId: currentClub.value.id, contextType: 'Club', businessRuleId },
        }),
      ]
    }

    const generateBusinessRuleResult = async ({
      aggregationId,
      contextType,
      contextId,
      businessRuleId,
      resultValue,
    }) => {
      if (contextType === 'User' && !contextId) throw new TypeError('who tf is this?')
      const dependents = {
        User: getUserDependents(businessRuleId, contextId),
        Club: getClubDependents(businessRuleId),
      }[contextType] || []

      Object.keys(loadingStatuses).forEach(id => { loadingStatuses[id] = true })
      try {
        const res = await BusinessRuleApi.generateBusinessRuleResult({
          aggregationId,
          contextType,
          contextId,
          businessRuleId,
          resultValue,
          date: date.value,
          dependents,
        })
        if (res.data.status === 'success') {
          res.data.data.data.forEach(businessRuleResult => updateAggregationResult(businessRuleResult))
        }
      } catch (error) {
        handleApiError(error, { $toast: vm.$toast })
      } finally {
        Object.keys(loadingStatuses).forEach(id => { loadingStatuses[id] = false })
      }
    }

    const createEodClosingUserAggregation = async (
      id,
      { userId, callback } = { userId: null, callback: () => {} },
    ) => {
      try {
        const res = await AggregationUserApi.createAggregationUser(id, userId)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          const aggIdx = aggregationsData.value.findIndex(ag => ag.attributes.id === Number(id))
          if (aggIdx < 0) return

          const updatedAggData = { ...aggregationsData.value[aggIdx] }
          updatedAggData.attributes.aggregationUsers.data.push(data)
          aggregationsData.value.splice(aggIdx, 1, updatedAggData)

          const updatedAvailableUsers = [...availableUsers.value[id]]
          const userIdx = updatedAvailableUsers.findIndex(user => user.attributes.id === Number(userId))
          if (userIdx < 0) return

          updatedAvailableUsers.splice(userIdx, 1)
          availableUsers.value = { ...availableUsers.value, [id]: updatedAvailableUsers }
        }
      } catch (error) {
        handleApiError(error, { $toast: vm.$toast })
      } finally {
        callback()
      }
    }

    const destroyEodClosingUserAggregation = async (
      aggregationId,
      { id, userId, callback } = { id: null, userId: null, callback: () => {} },
    ) => {
      try {
        const res = await AggregationUserApi.destroyAggregationUser(id)
        if (res.data.status === 'success') {
          const { data } = res.data.data

          const aggIdx = aggregationsData.value.findIndex(ag => ag.attributes.id === Number(aggregationId))
          if (aggIdx < 0) return

          const updatedAggData = { ...aggregationsData.value[aggIdx] }
          const aggUserIdx = updatedAggData.attributes.aggregationUsers.data.findIndex(aggUser => aggUser.attributes.id === Number(id))
          if (aggUserIdx < 0) return

          updatedAggData.attributes.aggregationUsers.data.splice(aggUserIdx, 1)
          aggregationsData.value.splice(aggIdx, 1, updatedAggData)

          const updatedAvailableUsers = [...availableUsers.value[id]]
          const userIdx = updatedAvailableUsers.findIndex(user => user.attributes.id === Number(userId))
          if (userIdx < 0) return

          updatedAvailableUsers.splice(userIdx, 1, data)
          availableUsers.value = { ...availableUsers.value, [id]: updatedAvailableUsers }
        }
      } catch (error) {
        handleApiError(error, { $toast: vm.$toast })
      } finally {
        callback()
      }
    }

    const handleFetchAvailableUsers = async (id, callback = () => {}) => {
      try {
        const res = await AggregationApi.getAvailableUsers(id)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          availableUsers.value = { ...availableUsers.value, [id]: data }
        }
      } catch (error) {
        handleApiError(error, { $toast: vm.$toast })
      } finally {
        callback()
      }
    }

    // ユーザー集計グループ用キュー投入メソッド
    const enqueueUserAggregations = async () => {
      const promises = eodClosingUserAggregationsData.value
        .filter(agg => {
          const { id } = agg.attributes

          return jobStatuses.value[id] === null || jobStatuses.value[id] === 'failed'
        })
        .map(agg => enqueueAggregation(agg.attributes.id))
      await Promise.all(promises)
    }

    // クラブ集計グループ用キュー投入メソッド
    const enqueueClubAggregations = async () => {
      const promises = eodClosingClubAggregationsData.value
        .filter(agg => {
          const { id } = agg.attributes

          return jobStatuses.value[id] === null || jobStatuses.value[id] === 'failed'
        })
        .map(agg => enqueueAggregation(agg.attributes.id))
      await Promise.all(promises)
    }

    onMounted(async () => {
      await getAggregations()
    })

    return {
      aggregations,
      eodClosingUserAggregationsMap,
      eodClosingClubAggregationsMap,
      eodClosingUserAggregationsData,
      eodClosingClubAggregationsData,
      loadingStatuses,
      jobStatuses,
      availableUsers,
      isAnyJobInProgress,
      isAnyJobMissing,
      isAllJobCompleted,
      isAllJobMissing,
      aggregationUsers,
      canEnqueueJobs,
      jobTimestamps,
      isLoading,

      showSkeletonLoader,
      aggregationResultsMap,

      showFormattedTimestamp,
      reloadAggregation,
      refreshAggregation,
      enqueueGenerateBusinessRuleResults,
      generateBusinessRuleResult,
      createEodClosingUserAggregation,
      destroyEodClosingUserAggregation,
      handleFetchAvailableUsers,
      enqueueUserAggregations,
      enqueueClubAggregations,

      icons: {
        mdiSync,
        mdiReload,
      },
    }
  },
}
</script>
